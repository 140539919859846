import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGraphApi } from 'utils/hooks';
import { entityTemplatesFieldsSection } from 'behavior/entityTemplates';

export default isDesignerMode => {
  const fields = useSelector(state => state.page.templateFields);

  if (!isDesignerMode)
    return fields;

  const [loadedFields, setLoadedFields] = useState();
  const apiCall = useGraphApi();

  useEffect(() => {
    const fieldsQuery = `{profile{contactUsFields{${entityTemplatesFieldsSection}}}}`;
    apiCall(fieldsQuery, undefined, r => r.profile.contactUsFields).subscribe({ next: setLoadedFields });
  }, []);

  return fields ?? loadedFields;
};
