export default Object.freeze([
  {
    id: 'hqvjajy2l',
    spacing: {
      margin: '9px 0',
      padding: '0',
    },
    columns: [
      {
        id: '91wtzbv3d',
        contentBlocks: [
          {
            id: 'aw4moewrh',
            name: 'SanaText',
            model: {
              key: 'ChangePassword',
              styleMode: 'Heading1',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'rdp9x72rp',
    spacing: {
      margin: '0 0 13px',
      padding: '0',
    },
    columns: [
      {
        id: 'pxm3e7pav',
        contentBlocks: [
          {
            id: '8xloobdil',
            name: 'SanaText',
            model: {
              key: 'ChangePassword_Description',
              styleMode: 'None',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'mzglw4k1h',
    spacing: {
      margin: '0 0 20px 0',
      padding: '0',
    },
    columns: [
      {
        id: 'cwaguh3lf',
        weight: 6,
        colspan: {
          sm: 6,
          md: 6,
          lg: 6,
        },

        contentBlocks: [
          {
            id: 'xnxico0kw',
            name: 'ResetPasswordForm',
          },
        ],
      },
      {
        id: 'gsttlakkh',
        colspan: {
          sm: 6,
          md: 6,
          lg: 6,
        },
        weight: 6,
        contentBlocks: [],
      },
    ],
  },
]);
