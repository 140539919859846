export default Object.freeze([
  {
    id: '5vf4aa6fe',
    spacing: {
      margin: '0 0 30px 0',
      padding: '12px 0',
    },
    columns: [
      {
        id: 'acq5np7ji',
        contentBlocks: [
          {
            id: 'ue7p5zs0e',
            name: 'SanaText',
            model: {
              key: 'CreateAccount_MainHeader',
              styleMode: 'Heading1',
            },
            spacing: {
              padding: '9px 0',
            },
          },
          {
            id: 'c9ijegaz1',
            name: 'SanaText',
            model: {
              key: 'CreateAccount_MainIntroductionLabel',
              styleMode: 'None',
            },
          },
          {
            id: 'tw8v4jvst',
            name: 'RegistrationForm',
            spaing: {
              padding: '20px 0',
            },
            model: {
              showSectionHeaders: true,
            },
          },
        ],
      },
    ],
  },
]);
