import styles from '../../Login.module.scss';
import { routesBuilder } from 'routes';
import { SanaLinkButton } from 'components/primitives/links';
import { useSelector } from 'react-redux';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { RouteName } from 'routes';
import { SimpleText, RichText } from 'components/sanaText';

type Props = {
  isDesignerMode?: boolean;
};

const GuestCheckoutButtonBlock = ({ isDesignerMode }: Props) => {
  const [hasAbility] = useHasAbilities(AbilityTo.CheckoutAsGuest);

  const dontShowGuestBlock = useSelector(s => {
    if (isDesignerMode)
      return false;

    if (!hasAbility ||
      s.user.isAuthenticated ||
      s.settings.loaded && !s.settings.profile.registrationAllowed ||
      !(s.basket.summary && 'totalCount' in s.basket.summary && s.basket.summary.totalCount))
      return true;

    const backToRouteData = s.page.backTo?.routeData;

    return !!backToRouteData
      && backToRouteData.routeName === RouteName.Checkout
      && backToRouteData.params?.asQuote;
  });

  if (dontShowGuestBlock)
    return null;

  return (
    <div className={styles.guestCheckout}>
      <h3 className={styles.title}><SimpleText textKey="Guest_DoNotWantToRegister" /></h3>
      <div className={styles.block}><RichText textKey="Guest_Description" /></div>
      <SanaLinkButton
        to={routesBuilder.forCheckout(false, null, true)}
        textKey="Login_GuestCheckoutButton"
        className={styles.guestCheckoutBtn}
      />
    </div>
  );
};

export default GuestCheckoutButtonBlock;
