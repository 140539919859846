import { memo } from 'react';
import { useSelector } from 'react-redux';
import { renderRows, extendDefaultSettings } from 'components/containers';
import { desktop as defaultDesktop } from './defaultContent';
import type { PublicStoreLoginPage } from 'behavior/pages/login';

const DesktopTemplate = () => {
  const desktop = useSelector(s => (s.page as PublicStoreLoginPage).content?.desktop);
  
  return renderRows(desktop ?? extendDefaultSettings(defaultDesktop));
};

export default memo(DesktopTemplate);
