export default Object.freeze([
  {
    columns: [
      {
        id: 'tt46ms1hy',
        contentBlocks: [
          {
            id: 'dc8dpwwvq',
            model: {
              key: 'ContactUs_Title',
              styleMode: 'Heading1',
            },
            name: 'SanaText',
          },
        ],
      },
    ],
    id: 'ckixegmlz',
    spacing: {
      margin: '9px 0',
      padding: '0',
    },
  },
  {
    columns: [
      {
        contentBlocks: [
          {
            id: 'p3m0b7q9k',
            model: {
              key: 'ContactUs_Body',
              styleMode: 'None',
            },
            name: 'SanaText',
          },
        ],
        id: 'imd90lsw3',
      },
    ],
    id: '786eg9eyl',
    spacing: {
      margin: '0 0 25px',
      padding: '0',
    },
  },
  {
    columns: [
      {
        colspan: {
          lg: 6,
          md: 6,
          sm: 6,
        },
        'contentBlocks': [
          {
            id: 'aw8ew3gmk',
            model: {
              key: 'ContactForm_Email',
              styleMode: 'Heading2',
            },
            name: 'SanaText',
            spacing: {
              margin: '0 0 25px',
            },
          },
          {
            id: '8gsabmwqm',
            name: 'ContactUsForm',
          },
        ],
        id: '21vd0hd2q',
      },
      {
        'colspan': {
          lg: 6,
          md: 6,
          sm: 6,
        },
        contentBlocks: [],
        id: 'f9f7jw04d',
      },
    ],
    id: 'j9atsu047',
    spacing: {
      padding: '0',
    },
  },
]);