import styles from 'components/objects/forms/SanaForm.module.scss';
import loginStyles from '../Login.module.scss';
import { useCallback, useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SanaForm, FormGroup, FieldLabel, EmailAddressField, PasswordField, CheckboxField } from 'components/objects/forms';
import { login, resetLoginFailed } from 'behavior/user';
import { Link } from 'components/primitives/links';
import { SanaButton } from 'components/primitives/buttons';
import { SimpleText, useSimpleTexts } from 'components/sanaText';
import { routesBuilder } from 'routes';
import LoginFailedMessage from './LoginFailedMessage';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const LoginForm = ({ formName = 'Login', disableAll, className }) => {
  const dispatch = useDispatch();
  const showRememberMe = useSelector(s => s.settings.profile?.showRememberMe);
  const onSubmit = useHandlerLockerInPreview(useCallback(formData => {
    dispatch(login(formData));
  }, []));

  const formikRef = useRef();

  const handleForgotPasswordClick = (_e, routeData) => {
    routeData.options.email = formikRef.current.values.email;
  };

  useEffect(() => () => dispatch(resetLoginFailed()), []);

  const [emailTitle, passwordTitle, rememberMeTitle] = useSimpleTexts([
    'EmailAddress',
    'Password',
    'Login_RememberMe',
  ]).texts;

  return (
    <SanaForm className={className} name={formName} onSubmit={onSubmit} formikRef={formikRef} method="post">
      <LoginFailedMessage onResetPasswordClick={handleForgotPasswordClick} />
      <FormGroup
        fieldName="email"
        fieldTitle={emailTitle}
        fieldComponent={EmailAddressField}
        maxLength={80}
        required
        validation={{
          required: true,
        }}
        disabled={disableAll}
      />
      <FormGroup
        fieldName="password"
        fieldTitle={passwordTitle}
        fieldComponent={PasswordField}
        maxLength={30}
        required
        validation={{
          required: { allowWhitespace: true },
        }}
        disabled={disableAll}
      />
      <div className={`${styles.formRow} ${loginStyles.rowActions} ${styles.noOffset}`}>
        <div className={loginStyles.forgotPassword}>
          <Link
            to={routesBuilder.forForgotPassword}
            options={{ email: '' }}
            onClick={handleForgotPasswordClick}
            data-prevent-validation
            disabled={disableAll}
          >
            <SimpleText textKey="ForgotPassword" />
          </Link>
        </div>
        {showRememberMe && (
          <span className={loginStyles.rememberMe}>
            <CheckboxField fieldName="persistent" disabled={disableAll} />
            <FieldLabel fieldName="persistent">
              {rememberMeTitle}
            </FieldLabel>
          </span>
        )}
        <SanaButton
          textKey="Login"
          type="submit"
          className={`${styles.btn} ${loginStyles.submitBtn}`}
          onClick={disableAll ? e => e.preventDefault() : undefined}
        />
      </div>
    </SanaForm>
  );
};

LoginForm.propTypes = {
  formName: PropTypes.string,
  disableAll: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(LoginForm);
