export default Object.freeze([
  {
    id: 'b762y3wcj',
    spacing: {
      margin: '0',
      padding: '0',
    },
    columns: [
      {
        id: 'ik9gyu7ci',
        contentBlocks: [
          {
            id: 'oaezyotsk',
            name: 'ProfileBackLink',
            spacing: {
              padding: '12px 0 0',
            },
          },
        ],
      },
    ],
  },
  {
    id: '9mtp9cslc',
    spacing: {
      margin: '9px 0',
      padding: '0',
    },
    columns: [
      {
        id: 'tcbtmlatb',
        contentBlocks: [
          {
            id: 'jamsq13as',
            name: 'SanaText',
            model: {
              key: 'ChangePassword',
              styleMode: 'Heading1',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'e9t0fccfn',
    spacing: {
      margin: '0',
      padding: '0 0 20px',
    },
    columns: [
      {
        id: '8fekixwt5',
        contentBlocks: [
          {
            id: 'h8pje12dx',
            name: 'ProfileMenu',
            model: {
              displayMode: 1,
            },
          },
        ],
      },
    ],
  },
  {
    id: 'zjlqq8toq',
    spacing: {
      margin: '0',
      padding: '0',
    },
    columns: [
      {
        id: 'efqeeh3lo',
        contentBlocks: [
          {
            id: 'g6s4qy8kv',
            name: 'SanaText',
            model: {
              key: 'ChangePassword_Description',
              styleMode: 'None',
            },
          },
        ],
      },
    ],
  },
  {
    id: '776a8b9su',
    spacing: {
      margin: '0',
      padding: '0 0 20px',
    },
    columns: [
      {
        id: 'nj1qxg1gy',
        contentBlocks: [
          {
            id: '8mr96ycb2',
            name: 'ChangePasswordForm',
          },
        ],
      },
    ],
  },
]);
