import styles from '../../Login.module.scss';
import LoginForm from '../../form';

type Props = {
  id: string;
  isDesignerMode?: boolean;
};

const FormBlock = ({ id, isDesignerMode }: Props) => (
  <LoginForm className={styles.form} formName={`Login_${id}`} disableAll={isDesignerMode} />
);

export default FormBlock;
