export default Object.freeze([
  {
    columns: [
      {
        contentBlocks: [
          {
            id: '75m47u69g',
            name: 'LoginPageTitle',
          },
        ],
        id: 'pi4ry1a8t',
      },
    ],
    id: '4w87m4x1h',
    spacing: {
      margin: '9px 0',
      padding: '0',
    },
  },
  {
    columns: [
      {
        colspan: {
          lg: 6,
          md: 6,
          sm: 6,
        },
        contentBlocks: [
          {
            id: '3r8etj6bq',
            name: 'SanaText',
            model: {
              key: 'Login_AlreadyCustomerHeader',
              styleMode: 'Heading3',
            },
            spacing: {
              margin: '0px 0px 5px',
            },
          },
          {
            id: 'rsjf534os',
            name: 'SanaText',
            model: {
              key: 'Login_IntroductionText',
              styleMode: 'None',
            },
            spacing: {
              margin: '0px 0px 26px',
            },
          },
          {
            id: 'v3f4dtau5',
            name: 'LoginForm',
          },
        ],
        id: '4b1l50u6u',
        padding: '12px 0 0',
      },
      {
        colspan: {
          lg: 6,
          md: 6,
          sm: 6,
        },
        contentBlocks: [
          {
            id: '6oxp5p1a3',
            name: 'LoginCreateAccountButton',
            spacing: {
              margin: '0 0 26px',
            },
          },
          {
            id: 'bo9npmk0c',
            name: 'LoginGuestCheckoutButton',
          },
        ],
        id: 'ojz9ze0jo',
        padding: '12px 0 0',
      },
    ],
    id: '048p1ph1m',
    spacing: {
      margin: '0',
      padding: '13px 0 26px',
    },
  },
]);
