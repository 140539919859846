import styles from './ForgotPassword.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SanaForm, FormGroup, CaptchaFormGroup, EmailAddressField, useCaptchaOnSubmit } from 'components/objects/forms';
import { submitForgotPassword } from 'behavior/pages/forgotPassword';
import { useSimpleTexts } from 'components/sanaText';
import { FormName } from 'behavior/pages';
import { DesignerModeCaptcha } from 'components/primitives/captcha';

const ForgotPasswordFormBlock = ({ id, email, submitForgotPassword, isDesignerMode, className = '' }) => {
  const formName = `${FormName.ForgotPassword}_${id}`;
  const [emailTitle] = useSimpleTexts(['EmailAddress']).texts;

  const { onSubmit, captchaRef } = useCaptchaOnSubmit(formData => {
    submitForgotPassword(formData, formName);
  }, FormName.ForgotPassword, [formName]);

  return (
    <SanaForm
      onSubmit={onSubmit}
      name={formName}
      initialValues={{ email: isDesignerMode ? '' : email }}
      focus={email != null}
      className={`${styles.form} ${className}`}
    >
      <FormGroup
        fieldName="email"
        fieldTitle={emailTitle}
        fieldComponent={EmailAddressField}
        maxLength={80}
        required
        validation={{ required: true }}
        disabled={isDesignerMode}
      />
      {isDesignerMode
        ? <DesignerModeCaptcha className={styles.vdCaptcha} />
        : <CaptchaFormGroup ref={captchaRef} captchaId={formName} />
      }
      <SanaForm.SubmitRow
        className={styles.noBottomMargin}
        onClick={isDesignerMode ? e => e.preventDefault() : undefined}
      />
    </SanaForm>
  );
};

ForgotPasswordFormBlock.propTypes = {
  id: PropTypes.string.isRequired,
  email: PropTypes.string,
  submitForgotPassword: PropTypes.func.isRequired,
  isDesignerMode: PropTypes.bool,
  className: PropTypes.string,
};

export default connect(
  state => ({ email: state.user?.email || state.page.options?.email }),
  { submitForgotPassword },
)(ForgotPasswordFormBlock);
