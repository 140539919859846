exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ContactUs_vd-captcha{margin-bottom:1.75rem}.ContactUs_no-bottom-margin{margin-bottom:0}@media (min-width:env(--theme-breakpoints_Medium,960)px),print{.ContactUs_vd-captcha{margin-left:36%;text-align:left}}", ""]);

// exports
exports.locals = {
	"vd-captcha": "ContactUs_vd-captcha",
	"vdCaptcha": "ContactUs_vd-captcha",
	"no-bottom-margin": "ContactUs_no-bottom-margin",
	"noBottomMargin": "ContactUs_no-bottom-margin"
};