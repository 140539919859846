exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Registration_vd-captcha{margin-bottom:1.75rem;margin-top:1.75rem}.Registration_vd-captcha:empty{margin-top:0}.Registration_form{padding-top:1.4em}.Registration_customer-data-section legend{margin-bottom:1.4em}.Registration_no-bottom-margin{margin-bottom:0}@media (min-width:env(--theme-breakpoints_Small,600)px),print{.Registration_form-content{max-width:46.75em}}@media (min-width:env(--theme-breakpoints_Medium,960)px),print{.Registration_form-content{max-width:none}.Registration_vd-captcha{margin-left:36%;text-align:left}}", ""]);

// exports
exports.locals = {
	"vd-captcha": "Registration_vd-captcha",
	"vdCaptcha": "Registration_vd-captcha",
	"form": "Registration_form",
	"customer-data-section": "Registration_customer-data-section",
	"customerDataSection": "Registration_customer-data-section",
	"no-bottom-margin": "Registration_no-bottom-margin",
	"noBottomMargin": "Registration_no-bottom-margin",
	"form-content": "Registration_form-content",
	"formContent": "Registration_form-content"
};