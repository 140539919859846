import { memo } from 'react';
import { renderRows, extendDefaultSettings } from 'components/containers';
import { desktop as defaultDesktop } from './defaultContent';
import { RowContentElement } from 'behavior/content';

type Props = {
  desktop: RowContentElement[] | null;
};

const DesktopTemplate = ({ desktop }: Props) => renderRows(desktop ?? extendDefaultSettings(defaultDesktop));

export default memo(DesktopTemplate);
