import { RowContentElement } from 'behavior/content';
import { Media } from 'components/responsive';
import DesktopTemplate from './DesktopTemplate';
import MobileTemplate from './MobileTemplate';

type Props = {
  desktop: RowContentElement[] | null;
  mobile: RowContentElement[] | null;
};

const Page = ({ desktop, mobile }: Props) => (
  <>
    <Media at="xs">
      <MobileTemplate mobile={mobile} />
    </Media>
    <Media greaterThanOrEqual="sm">
      <DesktopTemplate desktop={desktop} />
    </Media>
  </>
);

export default Page;
