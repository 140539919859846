export default Object.freeze([
  {
    columns: [
      {
        contentBlocks: [
          {
            id: '75m47u69g',
            name: 'LoginPageTitle',
          },
        ],
        id: 'pi4ry1a8t',
      },
    ],
    id: '4w87m4x1h',
    spacing: {
      margin: '9px 0',
      padding: '0',
    },
  },
  {
    columns: [
      {
        contentBlocks: [
          {
            id: '5ta81yvqa',
            name: 'SanaText',
            model: {
              key: 'Login_AlreadyCustomerHeader',
              styleMode: 'Heading3',
            },
            spacing: {
              margin: '0px 0px 6px',
            },
          },
          {
            id: '80e959uyt',
            name: 'SanaText',
            model: {
              key: 'Login_IntroductionText',
              styleMode: 'None',
            },
            spacing: {
              margin: '0px 0px 27px',
            },
          },
          {
            id: '49lamw7ps',
            name: 'LoginForm',
            spacing: {
              margin: '0 0 32px',
            },
          },
          {
            id: '79xc0ac6s',
            name: 'LoginCreateAccountButton',
            spacing: {
              margin: '0 0 32px',
            },
          },
          {
            id: '6mo83du9q',
            name: 'LoginGuestCheckoutButton',
          },
        ],
        id: '1tsgwqkv6',
      },
    ],
    id: '7diue33vg',
    spacing: {
      margin: '0',
      padding: '13px 0 26px',
    },
  },
]);
