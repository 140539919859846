export default Object.freeze([
  {
    id: 'ft5gmqle2',
    spacing: {
      margin: '9px 0',
      padding: '0',
    },
    columns: [
      {
        id: 'm6l168r9f',
        contentBlocks: [
          {
            id: 'lsh9735px',
            name: 'SanaText',
            model: {
              key: 'ChangePassword',
              styleMode: 'Heading1',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'iqmivzx3z',
    spacing: {
      margin: '0 0 13px',
      padding: '0',
    },
    columns: [
      {
        id: 'inxbzksq6',
        contentBlocks: [
          {
            id: 'hguh2643y',
            name: 'SanaText',
            model: {
              key: 'ChangePassword_Description',
              styleMode: 'None',
            },
          },
        ],
      },
    ],
  },
  {
    id: '7l1vca1ql',
    spacing: {
      margin: '0 0 20px 0',
      padding: '0',
    },
    columns: [
      {
        id: 'bmfgzyt0e',
        contentBlocks: [
          {
            id: 'c7uqmjgek',
            name: 'ResetPasswordForm',
          },
        ],
      },
    ],
  },
]);
