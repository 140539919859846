export default Object.freeze([
  {
    id: 'bcygonbod',
    spacing: {
      margin: '9px 0',
      padding: '0',
    },
    columns: [
      {
        id: 'g34dv4ofv',
        contentBlocks: [
          {
            id: 'j9ult46wc',
            name: 'SanaText',
            model: {
              key: 'ForgotPassword',
              styleMode: 'Heading1',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'w7f49ru72',
    spacing: {
      margin: '0',
      padding: '0',
    },
    columns: [
      {
        id: 'pmqw3y1dj',
        contentBlocks: [
          {
            id: '5y45uaxn7',
            name: 'SanaText',
            model: {
              key: 'ForgotPassword_Intro',
              styleMode: 'None',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'h7nb8e33e',
    spacing: {
      margin: '0',
      padding: '0',
    },
    columns: [
      {
        id: 'x3890vx5t',
        weight: 6,
        colspan: {
          sm: 6,
          md: 6,
          lg: 6,
        },

        contentBlocks: [
          {
            id: 'jjgyox1uj',
            name: 'ForgotPasswordForm',
            spacing: {
              margin: '0 0 20',
              padding: '0',
            },
          },
        ],
      },
      {
        id: 'y6etz8mar',
        colspan: {
          sm: 6,
          md: 6,
          lg: 6,
        },
        weight: 6,
        contentBlocks: [],
      },
    ],
  },
]);
