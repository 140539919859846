import { memo } from 'react';
import { useSelector } from 'react-redux';
import { renderRows, extendDefaultSettings } from 'components/containers';
import { mobile as defaultMobile } from './defaultContent';
import type { PublicStoreLoginPage } from 'behavior/pages/login';

const MobileTemplate = () => {
  const mobile = useSelector(s => (s.page as PublicStoreLoginPage).content?.mobile);

  return renderRows(mobile ?? extendDefaultSettings(defaultMobile));
};

export default memo(MobileTemplate);
