export default Object.freeze([
  {
    id: '96rfganva',
    spacing: {
      margin: '0',
      padding: '0',
    },
    columns: [
      {
        id: 'ff475gba9',
        contentBlocks: [
          {
            id: 'oygfy9x8d',
            name: 'ProfileBackLink',
            spacing: {
              padding: '22px 0 0',
            },
          },
        ],
      },
    ],
  },
  {
    id: '4ob8jwph2',
    spacing: {
      margin: '0',
      padding: '0',
    },
    columns: [
      {
        id: 'izo2b0w6s',
        colspan: {
          sm: 3,
          md: 3,
          lg: 3,
        },
        weight: 3,
        contentBlocks: [],
      },
      {
        id: '125wxk3le',
        colspan: {
          sm: 9,
          md: 9,
          lg: 9,
        },
        weight: 9,
        contentBlocks: [
          {
            id: 'kokic7sfn',
            name: 'SanaText',
            model: {
              key: 'ChangePassword',
              styleMode: 'Heading1',
            },
            spacing: {
              padding: '9px 0',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'rfqnelgcp',
    spacing: {
      margin: '0',
      padding: '0 0 20px',
    },
    columns: [
      {
        id: '4bk6k5tmt',
        colspan: {
          sm: 3,
          md: 3,
          lg: 3,
        },
        weight: 3,
        contentBlocks: [
          {
            id: 'cp383i9f9',
            name: 'ProfileMenu',
            model: {
              displayMode: 1,
            },
          },
        ],
      },
      {
        id: '4nhcumrav',
        colspan: {
          sm: 9,
          md: 9,
          lg: 9,
        },
        weight: 9,
        contentBlocks: [
          {
            id: 'e6xbypqcp',
            name: 'SanaText',
            model: {
              key: 'ChangePassword_Description',
              styleMode: 'None',
            },
          },
          {
            id: 'g7osq2jid',
            name: 'ChangePasswordForm',
            spacing: {
              padding: '13px 0 20px',
            },
          },
        ],
      },
    ],
  },
]);
