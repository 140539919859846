import { Media } from 'components/responsive';
import DesktopTemplate from './DesktopTemplate';
import MobileTemplate from './MobileTemplate';

const Page = () => (
  <>
    <Media at="xs">
      <MobileTemplate />
    </Media>
    <Media greaterThanOrEqual="sm">
      <DesktopTemplate />
    </Media>
  </>
);

export default Page;
