export default Object.freeze([
  {
    columns: [
      {
        contentBlocks: [
          {
            id: 'ohe65n148',
            model: {
              key: 'ContactUs_Title',
              styleMode: 'Heading1',
            },
            name: 'SanaText',
          },
        ],
        id: 'sep8ljov4',
      },
    ],
    id: 'y18yvycn3',
    spacing: {
      margin: '9px 0',
      padding: '0',
    },
  },
  {
    columns: [
      {
        contentBlocks: [
          {
            id: '55urzhee5',
            model: {
              key: 'ContactUs_Body',
              styleMode: 'None',
            },
            name: 'SanaText',
            spacing: {
              'margin': '0 0 25px',
            },
          },
          {
            id: 'taqkio76z',
            model: {
              key: 'ContactForm_Email',
              styleMode: 'Heading2',
            },
            name: 'SanaText',
            spacing: {
              'margin': '0 0 25px',
            },
          },
          {
            id: '2yxhqnmmk',
            name: 'ContactUsForm',
          },
        ],
        id: 'axv4ujxmw',
      },
    ],
    id: 'wr709p17e',
    spacing: {
      padding: '0',
    },
  },
]);