export default Object.freeze([
  {
    id: 'gq5it4t8j',
    spacing: {
      margin: '9px 0px',
      padding: '0px',
    },
    columns: [
      {
        id: 'rl7nqi5r3',
        contentBlocks: [
          {
            id: 'iyg49czg0',
            name: 'SanaText',
            model: {
              key: 'ForgotPassword',
              styleMode: 'Heading1',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'kkqcf4x6h',
    spacing: {
      margin: '0px',
      padding: '0px',
    },
    columns: [
      {
        id: 'm22c3nkrw',
        contentBlocks: [
          {
            id: 'iwbe0xzvg',
            name: 'SanaText',
            model: {
              key: 'ForgotPassword_Intro',
              styleMode: 'None',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'ylggs8e4i',
    spacing: {
      margin: '0px 0px 20px',
      padding: '0px',
    },
    columns: [
      {
        id: 'it5kd8eou',
        contentBlocks: [
          {
            id: '3gtozbga1',
            name: 'ForgotPasswordForm',
          },
        ],
      },
    ],
  },
]);
