import styles from './Registration.module.scss';
import formStyles from 'components/objects/forms/SanaForm.module.scss';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import {
  SanaForm, EntityTemplateFields, FormGroup, NewPasswordFormGroup,
  CaptchaFormGroup, useCaptchaOnSubmit, extractEscapedTemplateFields,
} from 'components/objects/forms';
import { EmailAddressField, PasswordField } from 'components/objects/forms';
import { SimpleText, useSimpleTexts } from 'components/sanaText';
import { useDispatch, useSelector } from 'react-redux';
import { register } from 'behavior/user';
import { FormName } from 'behavior/pages';
import { formatText } from 'utils/formatting';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import { LoadingIcon } from 'components/primitives/buttons';
import useRegistrationFields from './useRegistrationFields';
import { DesignerModeCaptcha } from 'components/primitives/captcha';

const RegistrationFormBlock = ({ id, isDesignerMode, model: { showSectionHeaders } }) => {
  const dispatch = useDispatch();
  const formName = `${FormName.Registration}_${id}`;
  const templateFields = useRegistrationFields(isDesignerMode);
  const registrationResult = useSelector(state => state.page.registrationResult);
  const [isSpinnerDisplayed, displaySpinner] = useState(false);
  const { onSubmit, captchaRef } = useCaptchaOnSubmit(formData => {
    displaySpinner(true);
    dispatch(register(createRegistrationData(formData, templateFields), formName));
  }, FormName.Registration, [templateFields]);

  const { name, email } = useSelector(state => state.user);

  useEffect(() => {
    if (registrationResult == null)
      return;

    displaySpinner(false);
  }, [registrationResult]);

  const anyCustomerDataFields = !!(templateFields && templateFields.length);

  const [
    emailTitle,
    passwordTitle,
    minPasswordPlaceholderTemplate,
    confirmPasswordTitle,
    comparisonErrorMessage,
  ] = useSimpleTexts([
    'EmailAddress',
    'Password',
    'CreateAccount_MinPasswordLength',
    'ConfirmPassword',
    'CreateAccount_DuplicatePasswordError',
  ]).texts;

  const minPasswordLength = useSelector(({ settings: { profile } }) => profile && profile.minPasswordLength);
  const newPasswordPlaceholder = formatText(minPasswordPlaceholderTemplate, [minPasswordLength]);

  return (
    <SanaForm
      onSubmit={useHandlerLockerInPreview(onSubmit)}
      name={formName}
      className={styles.form}
    >
      <Row>
        {anyCustomerDataFields && (
          <Col xs={12} md={6}>
            <fieldset className={`${formStyles.fieldset} ${styles.customerDataSection}`}>
              {showSectionHeaders &&
                <legend className="h3">
                  <SimpleText textKey="CreateAccount_PersonalDataHeader" />
                </legend>
              }
              <div className={styles.formContent}>
                <EntityTemplateFields applyNamesEscaping fields={templateFields} disableAll={isDesignerMode} />
              </div>
            </fieldset>
          </Col>
        )}
        <Col
          xs={12}
          md={anyCustomerDataFields ? 6 : 8}
          lg={anyCustomerDataFields ? null : 7}
        >
          <fieldset className={`${formStyles.fieldset} ${styles.customerDataSection}`}>
            {showSectionHeaders &&
              <legend className="h3">
                <SimpleText textKey="CreateAccount_LoginDataHeader" />
              </legend>
            }
            <div className={styles.formContent}>
              <FormGroup
                fieldName="email"
                fieldTitle={emailTitle}
                fieldComponent={EmailAddressField}
                maxLength={80}
                required
                validation={{
                  required: true,
                  uniqueEmail: true,
                }}
                disabled={isDesignerMode}
              />
              <NewPasswordFormGroup
                fieldName="password"
                fieldTitle={passwordTitle}
                placeholder={newPasswordPlaceholder}
                userData={[name, email]}
                userInputs={['Address.Name', 'email']}
                disabled={isDesignerMode}
              />
              <FormGroup
                fieldName="confirmPassword"
                fieldTitle={confirmPasswordTitle}
                fieldComponent={PasswordField}
                autoComplete="off"
                maxLength={30}
                required
                validation={{
                  required: { allowWhitespace: true },
                  equalsTo: { to: 'password', message: comparisonErrorMessage },
                }}
                disabled={isDesignerMode}
              />
              {isDesignerMode
                ? <DesignerModeCaptcha className={styles.vdCaptcha} />
                : <CaptchaFormGroup ref={captchaRef} captchaId={formName} />
              }
            </div>
          </fieldset>
          <div className={styles.formContent}>
            <SanaForm.SubmitRow
              textKey="Submit"
              icon={isSpinnerDisplayed ? <LoadingIcon /> : undefined}
              className={styles.noBottomMargin}
              onClick={isDesignerMode ? e => e.preventDefault() : undefined}
            />
          </div>
        </Col>
      </Row>
    </SanaForm>
  );
};

RegistrationFormBlock.propTypes = {
  id: PropTypes.string.isRequired,
  isDesignerMode: PropTypes.bool,
  model: PropTypes.shape({
    showSectionHeaders: PropTypes.bool.isRequired,
  }).isRequired,
};

export default RegistrationFormBlock;

function createRegistrationData(formData, templateFields) {
  const { email, password, captchaToken, confirmPassword, ...customerData } = formData;
  return { email, password, captchaToken, customerData: extractEscapedTemplateFields(customerData, templateFields) };
}
